@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto+Slab&family=Hammersmith+One&display=swap');
:root {
	--header-color:#fff;
	--header-nav-color:#58738A;
	--header-nav-border-color:#889baa;
	--header-nav-hover-color:#fff;
	--footer-height:7rem;
	--footer-background-color:#bcb6b0;
	--footer-color:#333;
	--background-color:#e0dad4;
	--page-color:#f0eae4;
	--page-text-color:#222;
	--link-color:#58738A;
	--light-text-color:#efece8;
	--cta-background:#c19e78;
	--cta-highlight:#b68c62;
	--cta-text:#fff;
	--form-border-color:#c0bab4;
	--form-header-color:#403830;
	--form-element-color:#fff;
	--form-group-button-hover-active:#b0aaa4;
	--form-highlight-indent:1.3rem;
	--form-highlight-color:#07d;
	--form-valid-color:#4a4;
	--form-invalid-color:#c34;
	--form-invalid-text:#fff;
	--form-button-background:#fff;
	--tooltip-color:#fff;
	--tooltip-background-color:#000;
	--table-border-color:#000;
	--barchart-height:16rem;
	--barchart-height-labels:6rem;
	--barchart-background-color:#fff;
	--barchart-axis-color:#ccc;
	--color-success:#080;
	--color-fail:#c00;
	--color-pending:#00c;
	--font-roboto-slab:"Roboto Slab","Times New Roman",serif;
	--font-open-sans:"Open Sans",Arial,sans-serif;
	--font-logo:"Hammersmith One",Arial,sans-serif;
}
/* Generic elements */
* {box-sizing:border-box}
html {
	min-height:100%;
	background:var(--background-color);
	display:flex;
	flex-direction:column;
}
body {
	margin: 0;
	height:100%;
	color:var(--page-text-color);
	font-family:var(--font-open-sans);
	font-size:12pt;
	overflow-y:scroll;
	flex:1;
	display:flex;
	flex-direction:column;
}
h1 {
	margin:0;
	font-family:var(--font-roboto-slab);
	font-size:2.5rem;
}
h2 {
	margin:0;
	font-family:var(--font-roboto-slab);
	font-size:1.5rem;
}
h3 {
	margin:0.5rem 0;
	font-family:var(--font-roboto-slab);
	font-size:1.2rem;
}
p {margin:0}
a, .btn-link {
	position:relative;
	color:var(--link-color);
	text-decoration:none;
	border:none;
	font-weight:normal;
}
a:not(.no-decoration)::after, .btn-link::after {
	content:'';
	position:absolute;
	bottom:0;
	left:0;
	background-color:var(--link-color);
	height:2px;
	width:100%;
	transform:scaleX(0);
	transition:transform 0.2s ease-in-out;
}
a:hover::after, .btn-link:hover::after, a.active::after, .btn-link.active::after {transform:none}
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
	-webkit-appearance:none;
	margin:0;
}
input[type=number] {-moz-appearance: textfield}

dialog {
	display:block;
	position:fixed;
	z-index:100;
	margin:auto;
	padding:0;
	border:none;
	inset:0;
	border-radius:1rem;
	width:min(700px,95%);
	max-width:unset;
	transform:none;
	transition:transform 0.2s,opacity 0.2s;
}
dialog:not([open]) {
	pointer-events:none;
	opacity:0;
	transform:translateY(2rem);
}
dialog.wobble {animation:wobble 0.2s forwards}
dialog>form {
	display:flex;
	flex-direction:column;
}
.dialog-header {
	display:flex;
	gap:0.5rem;
	align-items:center;
	padding:1rem;
	background-color:#ddd;
}
.dialog-header-icon {opacity:0.5}
.dialog-header-text {
	flex:1;
	font-size:1.7rem;
	font-family:var(--font-roboto-slab);
}
.forexponentialForm .dialog-header-close {
	display:flex;
	justify-content:center;
	align-items:center;
	width:2.5rem;
	height:2.5rem;
	border-radius:1.25rem;
	font-size:1.5rem;
	background:#fff;
	color:#000;
	opacity:0.7;
	filter:drop-shadow(3px 3px 5px rgba(0,0,0,0.3));
	cursor:pointer;
	border:none;
}
.forexponentialForm .dialog-header-close:hover {background:#000}
.forexponentialForm .dialog-header-close:hover i {color:#fff}
.dialog-body {
	padding:1rem;
}
.dialog-footer {
	display:flex;
	justify-content:flex-end;
	background-color:#ddd;
	padding:0.5rem 1rem;
}
dialog[open]::backdrop {animation:fade-in 0.2s forwards}
@keyframes fade-in {
	from {background-color:rgba(0,0,0,0);backdrop-filter:none;}
	to {background-color:rgba(0,0,0,0.5);backdrop-filter:blur(10px);}
}
@keyframes wobble {
	0% {transform:rotate(0)}
	10% {transform:rotate(2deg)}
	30% {transform:rotate(-2deg)}
	50% {transform:rotate(2deg)}
	70% {transform:rotate(-2deg)}
	90% {transform:rotate(2deg)}
	100% {transform:rotate(0)}
}

/* Site structure */
#root {
	width:100%;
	display:flex;
	flex-direction:column;
	flex:1;
}
#root>.container {
	background:var(--page-color);
	filter:drop-shadow(0 0 5px rgba(0,0,0,0.4));
}
#root>.root-main {
	flex:1 0;
	padding:0 0.3rem;
	z-index:1;
}
#root>.wide-main {
	padding:0;
}
.container {
	position:relative;
	width:100%;
	padding:0;
}

/* Header */
header {
	position:sticky;
	top:0;
	left:0;
	width:100%;
	background:var(--header-color);
	filter:drop-shadow(0 0 5px rgba(0,0,0,0.4));
	z-index:10;
	display:flex;
	flex-direction:column;
}
header nav {
	position:absolute;
	right:0;
	top:100%;
	z-index:11;
	pointer-events:none;
}
header nav ul {
	display:flex;
	flex-direction:column;
	list-style:none;
	margin:0;
	padding:0;
}
header nav ul li {
	background-color:var(--header-nav-color);
	transform:scaleX(0);
	transform-origin:100% 0;
	transition:transform 0.2s;
	--menu-delay:70ms;
}
header nav.active {pointer-events:auto}
header nav.active ul li {transform:none}
header nav ul li:nth-child(1) {transition-delay:calc(var(--menu-delay) * 4)}
header nav ul li:nth-child(2) {transition-delay:calc(var(--menu-delay) * 3)}
header nav ul li:nth-child(3) {transition-delay:calc(var(--menu-delay) * 2)}
header nav ul li:nth-child(5) {transition-delay:calc(var(--menu-delay) * 1)}
header nav ul li:nth-child(6) {transition-delay:calc(var(--menu-delay) * 0)}
header nav.active ul li:nth-child(1) {transition-delay:calc(var(--menu-delay) * 0)}
header nav.active ul li:nth-child(2) {transition-delay:calc(var(--menu-delay) * 1)}
header nav.active ul li:nth-child(3) {transition-delay:calc(var(--menu-delay) * 2)}
header nav.active ul li:nth-child(5) {transition-delay:calc(var(--menu-delay) * 3)}
header nav.active ul li:nth-child(6) {transition-delay:calc(var(--menu-delay) * 4)}
header nav ul li:not(.menu-cta):not(.menu-spacer) {border-bottom:1px solid var(--header-nav-border-color)}
header nav ul li:first-child {border-top:1px solid var(--header-nav-border-color)}
header nav ul li:last-child {background:var(--header-nav-border-color)}
header nav ul .menu-spacer {margin-left:auto}
header nav ul li a {
	display:block;
	padding:1rem 3rem 1rem 2rem;
	color:#fff;
	text-transform:uppercase;
	transition:background-color 0.2s,color 0.2s;
}
header nav ul li a:hover, header nav ul li a.active {
	background:var(--header-nav-hover-color);
	color:var(--header-nav-color);
}
header .header-inner {
	display:flex;
	justify-content:space-between;
}
header .header-inner .header-logo a {
	display:flex;
	align-items:center;
	color:#000;
	font-size:1.5rem;
	font-family:var(--font-logo);
	font-weight:700;
}
header .header-logo img {
	padding:0.5rem;
	height:4rem;
}
header .header-inner .hamburger {
	position:relative;
	flex:0 0 3rem;
	padding:0;
	align-self:center;
	height:3rem;
	margin-right:1rem;
	border:2px solid var(--header-nav-color);
	border-radius:0.5rem;
	cursor:pointer;
	transition:background-color 0.2s;
	z-index:1;
}
header .hamburger:hover {background:var(--header-nav-color)}
header .hamburger .hamburger-bar {
	position:absolute;
	background:var(--header-nav-color);
	height:4px;
	width:70%;
	left:15%;
	border-radius:2px;
	transition:top 0.2s 0.2s,opacity 0s 0.2s,transform 0.2s,background-color 0.2s;
}
header .hamburger:hover .hamburger-bar {background:var(--header-color)}
header .hamburger.active .hamburger-bar {transition:top 0.2s,opacity 0s 0.2s,transform 0.2s 0.2s}
header .hamburger .hamburger-bar:first-child {top:calc(30% - 2px)}
header .hamburger .hamburger-bar:nth-child(2) {top:calc(50% - 2px)}
header .hamburger .hamburger-bar:last-child {top:calc(70% - 2px)}
header .hamburger.active .hamburger-bar:first-child {top:calc(50% - 2px); transform:rotate(45deg)}
header .hamburger.active .hamburger-bar:nth-child(2) {opacity:0}
header .hamburger.active .hamburger-bar:last-child {top:calc(50% - 2px); transform:rotate(-45deg)}
.header-blur {
	display:block;
	position:fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
	transition:backdrop-filter 0.2s;
	z-index:9;
	pointer-events:none;
}
.header-blur.active {
	backdrop-filter:blur(5px) brightness(0.8);
	filter:blur(0);
	pointer-events:auto;
}

/* Footer */
footer {
	width:100%;
	height:var(--footer-height);
	color:var(--footer-color);
	background:var(--footer-background-color);
	z-index:1;
}
footer>.container {
	height:100%;
	display:flex;
	align-items:center;
}
footer img {padding:0.5rem 2rem}
footer p {line-height:1rem;}
footer a {color:var(--footer-color); font-size:0.8rem;}
footer a:hover {text-decoration:underline}

/* FORM FIELDS */
.forexponentialForm {
	position:relative;
	width:100%;
	margin:auto;
	padding:0 3px;
}
.forexponentialForm .form-header {
	margin-top:1rem;
	padding:0.2rem 1rem;
	font-family:var(--font-roboto-slab);
	font-size:1.1rem;
	background:var(--form-border-color);
	text-align:center;
	color:var(--form-header-color);
}
.forexponentialForm input, .forexponentialForm select, .forexponentialForm textarea {
	position:relative;
	padding-left:1rem;
	border:1px solid var(--form-border-color);
	border-left:3px solid var(--form-border-color);
	border-radius:0;
	font-family:var(--font-open-sans);
	font-size:1rem;
	transition:all 0.1s ease-in-out;
}
.forexponentialForm .form-floating input[type="checkbox"], .forexponentialForm .form-floating input[type="radio"] {
	width:1.2rem;
	height:1rem;
}
.forexponentialForm .form-floating {
	position:relative;
	width:100%;
	margin:3px auto;
}
.forexponentialForm .form-floating input, .forexponentialForm .form-floating select {
	width:100%;
	height:3rem;
	padding:1rem 8px 0 calc(var(--form-highlight-indent) + 3px);
	background-color:var(--form-element-color);
}
.forexponentialForm .form-floating textarea {
	width:100%;
	height:100%;
	padding:1rem 8px 0 calc(var(--form-highlight-indent) + 3px);
	background-color:var(--form-element-color);
}
.forexponentialForm .form-floating input:not([type="file"]):focus, .forexponentialForm .form-floating select:focus, .forexponentialForm .form-floating textarea:focus {
	border-left-width:3px;
	border-color:var(--form-highlight-color);
	outline:none;
	z-index:1;
}
.forexponentialForm input[data-validated="true"], .forexponentialForm select[data-validated="true"], .forexponentialForm textarea[data-validated="true"] {
	border:1px solid var(--form-valid-color);
	border-left-width:3px;
}
.forexponentialForm input[data-validated="false"], .forexponentialForm select[data-validated="false"], .forexponentialForm textarea[data-validated="false"] {
	border:1px solid var(--form-invalid-color);
	border-left-width:3px;
}
.forexponentialForm input:disabled {
	padding:0;
	text-align:center;
}
/* The before pseudoelement, set up with no content and width 0, waiting to be activated */
.forexponentialForm .form-floating:has(input:not([type="file"]):not([type="checkbox"]):not([type="radio"]))::before, .forexponentialForm .form-floating:has(select)::before, .forexponentialForm .form-floating:has(textarea)::before {
	content:'';
	position:absolute;
	display:flex;
	justify-content:center;
	align-items:center;
	box-sizing:border-box;
	top:0;
	width:0;
	height:100%;
	font-family:"Font Awesome 5 Free";
	font-weight:900;
	color:var(--form-element-color);
	z-index:2;
	transition:width 0.1s,background-color 0.1s;
}
/* When valid data is entered */
.forexponentialForm .form-floating:has(input:not([type="file"]):not([type="checkbox"]):not([type="radio"])[data-validated="true"])::before, .forexponentialForm .form-floating:has(select[data-validated="true"])::before, .forexponentialForm .form-floating:has(textarea[data-validated="true"])::before {
	content:'\f00c';
	width:var(--form-highlight-indent);
	background:var(--form-valid-color);
	border-color:var(--form-valid-color);
}
/* When invalid data is entered */
.forexponentialForm .form-floating:has(input:not([type="file"]):not([type="checkbox"]):not([type="radio"])[data-validated="false"])::before, .forexponentialForm .form-floating:has(select[data-validated="false"])::before, .forexponentialForm .form-floating:has(textarea[data-validated="false"])::before {
	content:'\f00d';
	width:var(--form-highlight-indent);
	background:var(--form-invalid-color);
	border-color:var(--form-invalid-color);
}
/* Focused for editing */
.forexponentialForm .form-floating:has(input:not([type="file"]):not([type="checkbox"]):not([type="radio"]):focus)::before, .forexponentialForm .form-floating:has(select:focus)::before, .forexponentialForm .form-floating:has(textarea:focus)::before {
	content:'\f303';
	width:var(--form-highlight-indent);
	background:var(--form-highlight-color);
}
/* The floating labels */
.forexponentialForm .form-floating label {
	position:absolute;
	top:0.9rem;
	left:3px;
	opacity:0.7;
	transition:all 0.2s linear;
	pointer-events:none;
	z-index:2;
	padding-left:calc(var(--form-highlight-indent) + 3px);
}
.forexponentialForm .form-floating>input:focus~label, .forexponentialForm .form-floating>input:not(:placeholder-shown)~label, .forexponentialForm .form-floating>select~label, .forexponentialForm .form-floating>textarea:focus~label, .forexponentialForm .form-floating>textarea:not(:placeholder-shown)~label {
	top:0.3rem;
	font-size:0.7rem;
}
.forexponentialForm .form-floating>input[type="checkbox"]:focus~label, .forexponentialForm .form-floating>input[type="checkbox"]~label,
.forexponentialForm .form-floating>input[type="radio"]:focus~label, .forexponentialForm .form-floating>input[type="radio"]~label {
	position:relative;
	font-size:1rem;
	pointer-events:auto;
	top:0;
	padding-left:0;
	opacity:1;
}

/* Error messages */
.forexponentialForm .form-error {
	position:relative;
	display:flex;
	justify-content:center;
	align-items:stretch;
	color:var(--form-invalid-text);
	font-size:0.8rem;
	margin-top:-3px;
	margin-bottom:0.3rem;
	max-height:0;
	overflow:hidden;
	transition:max-height 0.3s;
}
.forexponentialForm .form-error.active {max-height:8rem}
.forexponentialForm .form-error > * {
	display:flex;
	align-items:center;
	background:var(--form-invalid-color);
	text-align:center;
	padding:0.5rem;
}
/* Grouped elements */
.forexponentialForm .form-group {
	display:flex;
	margin:3px 0;
}
.forexponentialForm .form-group .form-floating {margin:0}
.forexponentialForm .form-group .form-floating.form-floating-flex-width {width:unset}
.forexponentialForm .form-group>*:not(:first-child) input,
.forexponentialForm .form-group>*:not(:first-child):not(button) {border-left-width:0}
.forexponentialForm .form-group button {
	margin:0;
	height:initial;
}
.forexponentialForm .form-linked-boxes {
	display:flex;
	position:relative;
	border:1px solid var(--form-border-color);
	border-left:3px solid var(--form-border-color);
}
.forexponentialForm .form-linked-boxes:has(input[data-validated=true]) {border-color:var(--form-valid-color)}
.forexponentialForm .form-linked-boxes:has(input:not([data-validated=true])) {border-color:var(--form-border-color)}
.forexponentialForm .form-linked-boxes:has(input[data-validated=false]) {border-color:var(--form-invalid-color)}
.forexponentialForm .form-linked-boxes:has(input:focus) {
	border-color:var(--form-highlight-color);
	border-left:3px solid var(--form-highlight-color);
}
.forexponentialForm .form-linked-boxes input {
	height:calc(3rem - 2px);
	border:none;
	padding:1rem 0.5rem 0 0.5rem;
}
.forexponentialForm .form-linked-boxes .form-floating:before {
	display:none !important;
	content:'';
}
.forexponentialForm .form-linked-boxes .form-floating input:focus {border:none}
.forexponentialForm .form-linked-boxes .form-linked-text {
	height:calc(3rem - 2px);
	padding:1.25rem 0 0.6rem 0;
	background-color:var(--form-element-color);
}
.forexponentialForm .form-linked-boxes label {
	position:absolute;
	top:0.3rem;
	left:50%;
	white-space:nowrap;
	font-size:0.7rem;
	transform:translateX(-50%);
	z-index:3;
}

/* Button bank */
.forexponentialForm .form-buttons {
	display:flex;
	justify-content:center;
	flex-wrap:wrap;
}
.forexponentialForm .form-buttons button {padding:0 2rem}
.forexponentialForm.narrow {
	margin:auto;
	width:100%;
}
.forexponentialForm button {
	height:2.5rem;
	font-family:var(--font-open-sans);
	font-size:1rem;
	font-weight:bold;
	padding:0.4rem 1rem;
	border:3px solid var(--form-highlight-color);
	background:var(--form-button-background);
	color:var(--form-highlight-color);
	cursor:pointer;
	margin:0 1px;
	transition:all 0.2s;
}
.forexponentialForm button:hover {
	background:var(--form-highlight-color);
	color:var(--form-button-background);
}

.forexponentialForm .btn-grey {
	border-color:#666;
	color:#666;
}
.forexponentialForm .btn-grey:hover {background:#666}
.forexponentialForm .btn-red {
	border-color:var(--form-invalid-color);
	color:var(--form-invalid-color);
}
.forexponentialForm .btn-red:hover {background:var(--form-invalid-color)}
.forexponentialForm .btn-link {
	background-color:transparent;
	padding:0 !important;
	margin:0;
	height:unset;
	border:none;
	font-weight:normal;
	color:var(--link-color);
}
.forexponentialForm .btn-link:hover {background:unset;color:var(--link-color);}
.forexponentialForm button.disabled {
	opacity:0.4;
	cursor:not-allowed;
}
.forexponentialForm .btn-secondary {
	background-color:var(--form-border-color);
	border:1px solid var(--form-border-color);
	border-radius:0;
	color:#333;
}
.forexponentialForm .btn-secondary:hover {color:#333;background-color:var(--form-group-button-hover-active)}
.logo-creditcard {
	height:1.5rem;
	margin:0.2rem;
}

.btn-help {
	position:relative;
	white-space:pre-wrap;
}
.btn-help:focus, .btn-help[data-display=block] {
	z-index:9;
}
.btn-help::before {
	content:attr(data-text);
	display:block;
	position:absolute;
	box-sizing:border-box;
	top:0;
	left:var(--left,0);
	width:var(--width,250px);
	color:var(--tooltip-color);
	background-color:var(--tooltip-background-color);
	font-weight:400;
	padding:0.5rem;
	border-radius:0.3rem;
	transform:translate(0,calc(-100% - 10px));
}
.btn-help::after {
	content:'';
	display:block;
	position:absolute;
	width:0;
	height:0;
	top:0;
	left:50%;
	border:10px;
	border-left:5px solid transparent;
	border-right:5px solid transparent;
	border-top:10px solid var(--tooltip-background-color);
	transform:translate(-50%,-100%);
}
.btn-help[data-display=none]::before, .btn-help[data-display=none]::after {display:none}
.btn-help:focus::before, .btn-help:focus::after {display:block}

/* Tables */
.forexponentialTable {
	border-spacing:0;
	border-collapse:collapse;
	border:1px solid var(--table-border-color);
}
.forexponentialTable td, .forexponentialTable th {
	font-size:0.85rem;
	padding:0 0.2rem;
	border:1px solid var(--table-border-color);
	text-align:center;
}

/* Graphs */
.forexponentialBarChart {
	position:relative;
	display:inline-grid;
	grid-auto-flow:column;
	grid-auto-columns:minmax(1px,20px);
	height:var(--barchart-height);
	margin:0.5rem 0;
	background-color:var(--barchart-background-color);
}
.forexponentialBarChart .axis-separator-h {
	position:absolute;
	left:0;
	bottom:calc(var(--barchart-height-labels) - 1px);
	width:100%;
	height:1px;
	background-color:var(--barchart-axis-color);
}
.forexponentialBarChart .axis-separator-v {
	position:absolute;
	left:0;
	top:0;
	height:100%;
	width:1px;
	background-color:var(--barchart-axis-color);
}
.forexponentialBarChart .chart-element {
	position:relative;
}
.forexponentialBarChart .chart-element-selected {
	background:#ddd;
}
.forexponentialBarChart .chart-label {
	position:absolute;
	left:50%;
	top:calc(var(--barchart-height) - var(--barchart-height-labels) + 0.3rem);
	writing-mode:vertical-lr;
	text-align:end;
	margin:auto;
	width:100%;
	padding-bottom:0.3rem;
	transform:translateX(-50%);
}
.forexponentialBarChart .chart-bar {
	position:absolute;
	left:1px;
	bottom:var(--barchart-height-labels);
	width:calc(100% - 1px);
	min-height:1px;
}
.forexponentialBarChart .chart-line {
	position:absolute;
	left:1px;
	width:calc(100% - 1px);
	height:1px;
	background-color:var(--color-pending);
}

.forexponentialBarChart .chart-point {
	position:absolute;
	height:5px;
	width:5px;
	left:50%;
	border-radius:50%;
	background-color:var(--color-pending);
	transform:translateX(-50%);
}

.forexponentialForm button.cta, a.cta {
	padding:0;
	border:none;
	display:flex;
	height:auto;
	background:transparent;
	margin:auto;
	cursor:pointer;
}
.forexponentialForm button.cta:hover {background:transparent}
.forexponentialForm button.cta div:first-child, a.cta div:first-child {
	background-color:var(--cta-background);
	border-radius:13px 0 0 13px;
	padding:0.5rem 1rem;
	font-weight:normal;
	color:var(--cta-text);
	transition:background-color 0.5s;
}
.forexponentialForm button.cta div:last-child, a.cta div:last-child {
	position:relative;
	background-color:var(--cta-highlight);
	border-radius:0 13px 13px 0;
	padding:0.5rem 1rem;
	color:var(--cta-text);
}
.forexponentialForm button.cta div:last-child::after, a.cta div:last-child::after {
	content:"";
	position:absolute;
	left:50%;
	top:50%;
	height:0;
	border-left:12px solid var(--cta-text);
	border-right:0;
	border-top:7px solid transparent;
	border-bottom:7px solid transparent;
	color:var(--cta-text);
	transform:translate(-50%,-50%);
	transition:left 0.2s;
}
.forexponentialForm button.cta div:last-child::before, a.cta div:last-child::before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:0;
	border-radius:0 13px 13px 0;
	background-color:var(--cta-background);
	transition:background-color 0.5s, width 0.2s;
}
.forexponentialForm button.cta:hover div:first-child, a.cta:hover div:first-child {background-color:var(--cta-highlight)}
.forexponentialForm button.cta:hover div:last-child::after, a.cta:hover div:last-child::after {left:55%}
.forexponentialForm button.cta:hover div:last-child::before, a.cta:hover div:last-child::before {
	width:100%;
	background-color:var(--cta-highlight);
}

/* Items in rows */
.row-four {
	display:flex;
	justify-content:space-around;
	text-align:center;
	padding-top:1rem;
	padding-bottom:1rem;
	gap:1rem;
	flex-wrap:wrap;
}
.row-four > * {
	flex:100%;
	padding-top:1rem;
	padding-bottom:1rem;
}
.row-two {
	display:flex;
	justify-content:space-around;
	flex-direction:column;
}
.row-two > * {
	flex:1;
}

@media (prefers-reduced-motion) {
	header nav ul li {transition:none}
	.header-blur {transition:none}
}

@media (min-width:420px) {
	.row-four > * {flex:45%}
}
@media (min-width:780px) {
	.container {
		width:clamp(754px,90%,1400px);
		margin:0 auto;
		padding:0;
	}
	.forexponentialForm {width:min(100%,700px)}
	.forexponentialForm.narrow {width:30rem}
	.row-four > * {flex:1}
	.row-two {flex-direction:row}
	.row-make-second {order:2}
	
	header .header-inner .hamburger {display:none}
	header nav, header nav.active {
		background-color:var(--header-nav-color);
		position:relative;
		pointer-events:auto;
		/* order:0; */
	}
	header nav ul li {
		transform:none;
	}
	/* header > *:not(nav) {order:2} */
	header nav ul {
		display:flex;
		flex-direction:row;
	}
	header nav ul li:not(.menu-cta):not(.menu-spacer), header nav ul li.menu-spacer {border-bottom:none; border-right:1px solid var(--header-nav-border-color);}
	header nav ul li:first-child {border-top:none; border-left:1px solid var(--header-nav-border-color);}
	header nav ul li:last-child {background:var(--header-nav-border-color)}
	header nav ul li a {padding:0.75rem 1.5rem}
	header .header-logo img {
		padding:0.5rem 1rem;
		height:4rem;
		transition:all 0.2s;
	}
	header.top .header-logo img {
		padding:0.75rem 2rem;
		height:6rem;
	}
	header .header-inner .header-logo a {font-size:1.5rem}
	header.top .header-inner .header-logo a {font-size:2rem}
	.header-blur {display:none}
}
