:root {
	--stat-box-header:#432;
	--stat-box-figure:#654;
	--stat-box-text:#fff;
	--account-menu-color:#48637A;
}

/* Account menu */
.account-menu {
	position:relative;
	left:-0.3rem;
	display:grid;
	width:calc(100% + 0.6rem);
	justify-content:center;
	grid-template-columns:repeat(3,1fr);
}
.account-menu button {
	flex:1;
	padding:0.4rem 0.2rem;
	background-color:var(--account-menu-color);
	border:none;
	border-right:1px solid var(--header-nav-border-color);
	border-bottom:1px solid var(--header-nav-border-color);
	color:#fff;
	cursor:pointer;
	transition:color 0.2s,background-color 0.2s,border-bottom-color 0.2s;
}
.account-menu button:last-child {
	border-right:none;
}
.account-menu button:hover, .account-menu button.active {
	color:var(--account-menu-color);
	background-color:#fff;
	border-bottom-color:#fff;
}
.account-menu button i {
	margin:0.2rem 0.5rem;
}
.account-menu button p {
	margin:0.2rem 0;
}

/* Stat boxes */
.row-four > .stat-box {
	/* border:2px solid var(--header-nav-border-color); */
	padding:0;
	color:var(--stat-box-text);
	display:flex;
	flex-direction:column;
}
.stat-box .stat-header {
	padding:0.2rem;
	font-size:0.9rem;
	background-color:var(--account-menu-color);
}
.stat-box .stat-figure {
	flex:1;
	padding:0.2rem;
	font-size:2rem;
	font-family:var(--font-roboto-slab);
	font-weight:bold;
	background-color:var(--header-nav-color);
}

@media (min-width:780px) {
	.account-menu {
		grid-template-columns:repeat(auto-fit,minmax(0,1fr));
	}
	.account-menu button {
		padding:0.65rem 0.2rem;
		text-transform:uppercase;
	}
	.account-menu button p {
		display:inline-block;
	}	
}
