/* FAQ page */
.faq-container {
	width:100%;
	padding:0 0.5rem;
}
.faq {margin:1rem 0}
.faq-question {
	position:relative;
	display:flex;
	justify-content:flex-start;
	align-items:center;
	gap:0.5rem;
	width:100%;
	border:none;
	font-size:1rem;
	padding:0;
	color:var(--page-text-color);
	text-align:start;
	font-weight:bold;
	font-family:"Open Sans";
	background:#fff;
	box-shadow:0 0 10px 2px rgba(0,0,0,0.3);
	cursor:pointer;
}
.faq .faq-question .faq-indicator {
	flex:0 0 2rem;
	min-height:2rem;
	align-self:stretch;
	background:var(--link-color);
	color:#fff;
	display:flex;
	justify-content:center;
	align-items:center;
	position:relative;
}
.faq.selected .faq-question .faq-indicator {background:#79a}
.faq .faq-question .faq-indicator::before {
	content:"";
	position:absolute;
	width:1rem;
	height:3px;
	background:#fff;
	transition:0.2s;
}
.faq.selected .faq-question .faq-indicator::before {transform:rotate(180deg)}
.faq .faq-question .faq-indicator::after {
	content:"";
	position:absolute;
	width:3px;
	height:1rem;
	background:#fff;
	transition:0.2s;
}
.faq.selected .faq-question .faq-indicator::after {
	height:0;
	transform:rotate(180deg);
}
.faq .faq-answer {
	display:grid;
	grid-template-rows:0fr;
	padding:0.2rem 1rem;
	transition:grid-template-rows 0.2s,padding 0.2s;
}
.faq.selected .faq-answer {
	grid-template-rows:1fr;
	padding:1rem;
}
.faq-answer-inner {overflow:hidden}

@media (min-width:780px) {
	.faq-container {
		width:70%;
		padding:0;
		margin:auto;
	}
}
