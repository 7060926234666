/* Hero */
.hero {
	display:grid;
	grid-template-columns:1fr;
	grid-template-rows:auto;
	align-items:center;
}

.hero .hero-box {
	padding:0 1rem;
	background:var(--page-color);
	margin-bottom:1rem;
}
.hero .hero-box h1 {margin:2rem 0 1rem}
.hero .hero-box p {margin-bottom:1rem}
.hero .hero-box .cta {margin:unset}

.hero .hero-image {
	position:relative;
	margin-bottom:1rem;
	min-height:10rem;
	min-width:10rem;
	margin-left:auto;
	margin-right:auto;
}
.hero .hero-image img {
	position:absolute;
	display:block;
	width:10rem;
	aspect-ratio:1;
	margin:auto;
	object-fit:cover;
	border-radius:50%;
	object-position:50% 20%;
}

@media (min-width:780px) {
	.hero {
		grid-template-columns:45% 55%;
		grid-template-rows:1fr;
		/* grid-template-columns:1fr 1fr; */
	}
	.hero .hero-image {
		position:relative;
		display:block;
		height:30rem;
		margin:0;
	}
	/* .hero .hero-image::before {
		content:"";
		position:absolute;
		inset:0;
		background:linear-gradient(to left,rgba(255,255,255,0) 80%,rgba(240,234,228,1));
		z-index:1;
	} */
	.hero .hero-image img {
		clip-path: ellipse(53% 120% at 53% 50%);
		border-radius:0;
		width:100%;
		height:100%;
	}
	.hero .hero-box h1 {margin-top:0}
	.steps-section {width:70%}
}

/* Steps */
.steps-section {
	--step-margin:5rem;
	--step-line-width:10px;
	--step-number-width:3rem;
	--step-highlight-color:var(--cta-background);
	width:90%;
	padding:1rem 0;
	margin:auto;
}
.steps-container {padding:calc(var(--step-margin) / 2) 0}
.steps-header, .steps-footer {
	position:relative;
	top:0;
	display:inline-block;
	background:var(--step-highlight-color);
	padding:1rem 2rem;
	transform:translateX(-50%);
	font-family:var(--roboto-slab);
	font-size:1.2rem;
	color:var(--step-text);
	border-radius:0.5rem;
	z-index:1;
}
.steps-header {left:calc(50% + var(--step-margin) / 2)}
.steps-footer {left:calc(50% - var(--step-margin) / 2)}
.steps-step {
	position:relative;
	display:flex;
	justify-content:flex-start;
	align-items:center;
	margin:var(--step-margin) auto;
	isolation:isolate;
}
.steps-step:nth-child(even) {justify-content:flex-end}
.steps-step::before {
	content:"";
	position:absolute;
	box-sizing:border-box;
	width:calc(100% - var(--step-number-width) + var(--step-line-width));
	height:calc(100% + var(--step-margin) + var(--step-line-width));
	top:calc(0 - var(--step-margin) / 2);
	left:calc(var(--step-number-width) / 2 - var(--step-line-width) / 2);
	border:var(--step-line-width) solid var(--step-highlight-color);
	border-radius:calc(var(--step-margin) / 2);
	clip-path:inset(0 calc(50% - 1px) 0 0);
	z-index:-1;
}
.steps-step:nth-child(even)::before {clip-path:inset(0 0 0 calc(50% - 1px))}
.steps-step:first-child::after, .steps-step:last-child::after {
	content:"";
	position:absolute;
	box-sizing:border-box;
	width:calc(50% - var(--step-number-width) / 2 + var(--step-line-width) / 2);
	height:calc(var(--step-margin) * 1.5 + var(--step-line-width) / 2);
	border:var(--step-line-width) solid var(--step-highlight-color);
	border-radius:calc(var(--step-margin) / 2);
	left:calc(var(--step-number-width) / 2 + var(--step-margin) / 2);
}
.steps-step:first-child::after {
	top:0;
	transform:translateY(calc(-100% - var(--step-margin) / 2 + var(--step-line-width) / 2));
	clip-path:inset(calc(var(--step-margin) / 2) 0 0 calc(var(--step-margin) / 2));
}
.steps-step:nth-child(odd):last-child::after {
	top:100%;
	transform:translateY(calc(var(--step-margin) / 2 - var(--step-line-width) / 2));
	clip-path:inset(0 0 calc(var(--step-margin) / 2) calc(var(--step-margin) / 2));
}
.steps-step:nth-child(even):last-child::after {
	top:100%;
	left:unset;
	right:calc(var(--step-number-width) / 2 + var(--step-margin) / 2);
	transform:translateY(calc(var(--step-margin) / 2 - var(--step-line-width) / 2));
	clip-path:inset(0 calc(var(--step-margin) / 2) calc(var(--step-margin) / 2) 0);
}
.steps-step .step-number {
	flex:0 0 var(--step-number-width);
	width:var(--step-number-width);
	background:var(--step-highlight-color);
	aspect-ratio:1;
	text-align:center;
	font-size:2rem;
	font-family:'Roboto Slab';
	font-weight:bold;
	color:var(--step-text);
	border-radius:calc(var(--step-number-width) / 2);
}
.steps-step:nth-child(even) .step-number {order:2}
.steps-step .step-text {
	flex:1;
	padding:0 2rem;
}
.steps-step:nth-child(even) .step-text {text-align:end}
