.articles-container {
	display:grid;
	grid-template-columns:1fr;
	gap:1rem;
	margin:1rem;
}
.articles-container article {
	color:var(--light-text-color);
	position:relative;
	height:15rem;
	isolation:isolate;
	display:flex;
	flex-direction:column;
	justify-content:flex-end;
	border-radius:0.5rem;
	overflow:hidden;
	background-size:cover;
	background-position:center;
}
.articles-container article .content {
	--padding:1rem;
	width:100%;
	padding:var(--padding);
	background:linear-gradient(180deg,rgba(0,0,0,0),rgba(0,0,0,0.4) 20%,rgba(0,0,0,0.8));
}
.articles-container article .content .heading {
	position:relative;
	width:fit-content;
	max-width:100%;
}
.articles-container article .content .heading::after {
	content:"";
	position:absolute;
	background-color:var(--cta-highlight);
	left:calc(var(--padding) * -1);
	bottom:-5px;
	width:calc(100% + var(--padding));
	height:0.25rem;
}

@media (hover) and (pointer:fine) and (prefers-reduced-motion:no-preference) {
	.articles-container article .content .heading::after {
		transform:scaleX(0);
		transform-origin:0;
		transition:transform 0.2s 0.3s ease;
	}
	.articles-container article:hover .content .heading::after, .articles-container article:focus-within .content .heading::after {
		transform:scaleX(1);
		transition:transform 0.2s ease;
	}
	.articles-container article .content > *:not(h3) {
		display:grid;
		grid-template-rows:0fr;
		transition:grid-template-rows 0.2s 0.3s ease;
	}
	.articles-container article .content > *:not(h3) > * {
		overflow:hidden;
	}
	.articles-container article:hover .content > *:not(h3), .articles-container article:focus-within .content > *:not(h3) {
		grid-template-rows:1fr;
	}
}

@media (min-width:780px) {
	.articles-container {
		grid-template-columns:repeat(2,minmax(0,1fr));
	}
}

@media (min-width:1200px) {
	.articles-container {
		grid-template-columns:repeat(3,minmax(0,1fr));
	}
}
