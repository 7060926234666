/* Country selector */
.country-select-list {
	position:absolute;
	display:none;
	filter:drop-shadow(3px 3px 3px rgba(0,0,0,0.4)) drop-shadow(-3px 3px 3px rgba(0,0,0,0.4));
	top:100%;
	left:0;
	margin:0;
	text-align:start;
	padding:0.3rem;
	list-style-type:none;
	z-index:9;
	background:var(--form-element-color);
	border:1px solid var(--form-border-color) !important;
	height:20rem;
	overflow-y:scroll;
}
button:has(.country-select-button):focus ~ .country-select-list {display:block}
.country-select-list li {padding:2px;cursor:pointer}
.country-select-list li:hover {background:var(--form-border-color)}
.country-select-list li span {padding:0 3px}
.country-select-list li span:nth-child(3) {opacity:0.6}
.country-select-flag {display:inline-block;background:url('./images/flags22.png') no-repeat; width:22px; margin:0; font-size:0;}
.country-select-flag.ac {height:10px;background-position:0px 0px;}
.country-select-flag.ad {height:14px;background-position:-22px 0px;}
.country-select-flag.ae {height:10px;background-position:-44px 0px;}
.country-select-flag.af {height:14px;background-position:-66px 0px;}
.country-select-flag.ag {height:14px;background-position:-88px 0px;}
.country-select-flag.ai {height:10px;background-position:-110px 0px;}
.country-select-flag.al {height:15px;background-position:-132px 0px;}
.country-select-flag.am {height:10px;background-position:-154px 0px;}
.country-select-flag.ao {height:14px;background-position:-176px 0px;}
.country-select-flag.aq {height:14px;background-position:-198px 0px;}
.country-select-flag.ar {height:13px;background-position:-220px 0px;}
.country-select-flag.as {height:10px;background-position:-242px 0px;}
.country-select-flag.at {height:14px;background-position:-264px 0px;}
.country-select-flag.au {height:10px;background-position:-286px 0px;}
.country-select-flag.aw {height:14px;background-position:-308px 0px;}
.country-select-flag.ax {height:13px;background-position:-330px 0px;}
.country-select-flag.az {height:10px;background-position:-352px 0px;}
.country-select-flag.ba {height:10px;background-position:-374px 0px;}
.country-select-flag.bb {height:14px;background-position:-396px 0px;}
.country-select-flag.bd {height:12px;background-position:-418px 0px;}
.country-select-flag.be {height:15px;background-position:-440px 0px; width:20px;}
.country-select-flag.bf {height:14px;background-position:-460px 0px;}
.country-select-flag.bg {height:12px;background-position:-482px 0px;}
.country-select-flag.bh {height:12px;background-position:-504px 0px;}
.country-select-flag.bi {height:12px;background-position:-526px 0px;}
.country-select-flag.bj {height:14px;background-position:-548px 0px;}
.country-select-flag.bl {height:14px;background-position:-570px 0px;}
.country-select-flag.bm {height:10px;background-position:-592px 0px;}
.country-select-flag.bn {height:10px;background-position:-614px 0px;}
.country-select-flag.bo {height:14px;background-position:-636px 0px;}
.country-select-flag.bq {height:14px;background-position:-658px 0px;}
.country-select-flag.br {height:14px;background-position:-680px 0px;}
.country-select-flag.bs {height:10px;background-position:-702px 0px;}
.country-select-flag.bt {height:14px;background-position:-724px 0px;}
.country-select-flag.bv {height:15px;background-position:-746px 0px;}
.country-select-flag.bw {height:14px;background-position:-768px 0px;}
.country-select-flag.by {height:10px;background-position:-790px 0px;}
.country-select-flag.bz {height:14px;background-position:-812px 0px;}
.country-select-flag.ca {height:10px;background-position:-834px 0px;}
.country-select-flag.cc {height:10px;background-position:-856px 0px;}
.country-select-flag.cd {height:15px;background-position:-878px 0px;}
.country-select-flag.cf {height:14px;background-position:-900px 0px;}
.country-select-flag.cg {height:14px;background-position:-922px 0px;}
.country-select-flag.ch {height:15px;background-position:-944px 0px; width:17px;}
.country-select-flag.ci {height:14px;background-position:-961px 0px;}
.country-select-flag.ck {height:10px;background-position:-983px 0px;}
.country-select-flag.cl {height:14px;background-position:-1005px 0px;}
.country-select-flag.cm {height:14px;background-position:-1027px 0px;}
.country-select-flag.cn {height:14px;background-position:-1049px 0px;}
.country-select-flag.co {height:14px;background-position:-1071px 0px;}
.country-select-flag.cp {height:14px;background-position:-1093px 0px;}
.country-select-flag.cr {height:12px;background-position:-1115px 0px;}
.country-select-flag.cu {height:10px;background-position:-1137px 0px;}
.country-select-flag.cv {height:12px;background-position:-1159px 0px;}
.country-select-flag.cw {height:14px;background-position:-1181px 0px;}
.country-select-flag.cx {height:10px;background-position:-1203px 0px;}
.country-select-flag.cy {height:14px;background-position:-1225px 0px;}
.country-select-flag.cz {height:14px;background-position:-1247px 0px;}
.country-select-flag.de {height:12px;background-position:-1269px 0px;}
.country-select-flag.dg {height:10px;background-position:-1291px 0px;}
.country-select-flag.dj {height:14px;background-position:-1313px 0px;}
.country-select-flag.dk {height:15px;background-position:-1335px 0px;}
.country-select-flag.dm {height:10px;background-position:-1357px 0px;}
.country-select-flag.do {height:14px;background-position:-1379px 0px;}
.country-select-flag.dz {height:14px;background-position:-1401px 0px;}
.country-select-flag.ea {height:14px;background-position:-1423px 0px;}
.country-select-flag.ec {height:14px;background-position:-1445px 0px;}
.country-select-flag.ee {height:13px;background-position:-1467px 0px;}
.country-select-flag.eg {height:14px;background-position:-1489px 0px;}
.country-select-flag.eh {height:10px;background-position:-1511px 0px;}
.country-select-flag.er {height:10px;background-position:-1533px 0px;}
.country-select-flag.es {height:14px;background-position:-1555px 0px;}
.country-select-flag.et {height:10px;background-position:-1577px 0px;}
.country-select-flag.eu {height:14px;background-position:-1599px 0px;}
.country-select-flag.fi {height:12px;background-position:-1621px 0px;}
.country-select-flag.fj {height:10px;background-position:-1643px 0px;}
.country-select-flag.fk {height:10px;background-position:-1665px 0px;}
.country-select-flag.fm {height:11px;background-position:-1687px 0px;}
.country-select-flag.fo {height:15px;background-position:-1709px 0px;}
.country-select-flag.fr {height:14px;background-position:-1731px 0px;}
.country-select-flag.ga {height:15px;background-position:-1753px 0px;}
.country-select-flag.gb {height:10px;background-position:-1775px 0px;}
.country-select-flag.gd {height:12px;background-position:-1797px 0px;}
.country-select-flag.ge {height:14px;background-position:-1819px 0px;}
.country-select-flag.gf {height:14px;background-position:-1841px 0px;}
.country-select-flag.gg {height:14px;background-position:-1863px 0px;}
.country-select-flag.gh {height:14px;background-position:-1885px 0px;}
.country-select-flag.gi {height:10px;background-position:-1907px 0px;}
.country-select-flag.gl {height:14px;background-position:-1929px 0px;}
.country-select-flag.gm {height:14px;background-position:-1951px 0px;}
.country-select-flag.gn {height:14px;background-position:-1973px 0px;}
.country-select-flag.gp {height:14px;background-position:-1995px 0px;}
.country-select-flag.gq {height:14px;background-position:-2017px 0px;}
.country-select-flag.gr {height:14px;background-position:-2039px 0px;}
.country-select-flag.gs {height:10px;background-position:-2061px 0px;}
.country-select-flag.gt {height:13px;background-position:-2083px 0px;}
.country-select-flag.gu {height:11px;background-position:-2105px 0px;}
.country-select-flag.gw {height:10px;background-position:-2127px 0px;}
.country-select-flag.gy {height:12px;background-position:-2149px 0px;}
.country-select-flag.hk {height:14px;background-position:-2171px 0px;}
.country-select-flag.hm {height:10px;background-position:-2193px 0px;}
.country-select-flag.hn {height:10px;background-position:-2215px 0px;}
.country-select-flag.hr {height:10px;background-position:-2237px 0px;}
.country-select-flag.ht {height:12px;background-position:-2259px 0px;}
.country-select-flag.hu {height:10px;background-position:-2281px 0px;}
.country-select-flag.ic {height:14px;background-position:-2303px 0px;}
.country-select-flag.id {height:14px;background-position:-2325px 0px;}
.country-select-flag.ie {height:10px;background-position:-2347px 0px;}
.country-select-flag.il {height:15px;background-position:-2369px 0px;}
.country-select-flag.im {height:10px;background-position:-2391px 0px;}
.country-select-flag.in {height:14px;background-position:-2413px 0px;}
.country-select-flag.io {height:10px;background-position:-2435px 0px;}
.country-select-flag.iq {height:14px;background-position:-2457px 0px;}
.country-select-flag.ir {height:12px;background-position:-2479px 0px;}
.country-select-flag.is {height:15px;background-position:-2501px 0px;}
.country-select-flag.it {height:14px;background-position:-2523px 0px;}
.country-select-flag.je {height:12px;background-position:-2545px 0px;}
.country-select-flag.jm {height:10px;background-position:-2567px 0px;}
.country-select-flag.jo {height:10px;background-position:-2589px 0px;}
.country-select-flag.jp {height:14px;background-position:-2611px 0px;}
.country-select-flag.ke {height:14px;background-position:-2633px 0px;}
.country-select-flag.kg {height:12px;background-position:-2655px 0px;}
.country-select-flag.kh {height:13px;background-position:-2677px 0px;}
.country-select-flag.ki {height:10px;background-position:-2699px 0px;}
.country-select-flag.km {height:12px;background-position:-2721px 0px;}
.country-select-flag.kn {height:14px;background-position:-2743px 0px;}
.country-select-flag.kp {height:10px;background-position:-2765px 0px;}
.country-select-flag.kr {height:14px;background-position:-2787px 0px;}
.country-select-flag.kw {height:10px;background-position:-2809px 0px;}
.country-select-flag.ky {height:10px;background-position:-2831px 0px;}
.country-select-flag.kz {height:10px;background-position:-2853px 0px;}
.country-select-flag.la {height:14px;background-position:-2875px 0px;}
.country-select-flag.lb {height:14px;background-position:-2897px 0px;}
.country-select-flag.lc {height:10px;background-position:-2919px 0px;}
.country-select-flag.li {height:12px;background-position:-2941px 0px;}
.country-select-flag.lk {height:10px;background-position:-2963px 0px;}
.country-select-flag.lr {height:11px;background-position:-2985px 0px;}
.country-select-flag.ls {height:14px;background-position:-3007px 0px;}
.country-select-flag.lt {height:12px;background-position:-3029px 0px;}
.country-select-flag.lu {height:12px;background-position:-3051px 0px;}
.country-select-flag.lv {height:10px;background-position:-3073px 0px;}
.country-select-flag.ly {height:10px;background-position:-3095px 0px;}
.country-select-flag.ma {height:14px;background-position:-3117px 0px;}
.country-select-flag.mc {height:15px;background-position:-3139px 0px; width:21px;}
.country-select-flag.md {height:10px;background-position:-3160px 0px;}
.country-select-flag.me {height:10px;background-position:-3182px 0px;}
.country-select-flag.mf {height:14px;background-position:-3204px 0px;}
.country-select-flag.mg {height:14px;background-position:-3226px 0px;}
.country-select-flag.mh {height:11px;background-position:-3248px 0px;}
.country-select-flag.mk {height:10px;background-position:-3270px 0px;}
.country-select-flag.ml {height:14px;background-position:-3292px 0px;}
.country-select-flag.mm {height:14px;background-position:-3314px 0px;}
.country-select-flag.mn {height:10px;background-position:-3336px 0px;}
.country-select-flag.mo {height:14px;background-position:-3358px 0px;}
.country-select-flag.mp {height:10px;background-position:-3380px 0px;}
.country-select-flag.mq {height:14px;background-position:-3402px 0px;}
.country-select-flag.mr {height:14px;background-position:-3424px 0px;}
.country-select-flag.ms {height:10px;background-position:-3446px 0px;}
.country-select-flag.mt {height:14px;background-position:-3468px 0px;}
.country-select-flag.mu {height:14px;background-position:-3490px 0px;}
.country-select-flag.mv {height:14px;background-position:-3512px 0px;}
.country-select-flag.mw {height:14px;background-position:-3534px 0px;}
.country-select-flag.mx {height:12px;background-position:-3556px 0px;}
.country-select-flag.my {height:10px;background-position:-3578px 0px;}
.country-select-flag.mz {height:14px;background-position:-3600px 0px;}
.country-select-flag.na {height:14px;background-position:-3622px 0px;}
.country-select-flag.nc {height:10px;background-position:-3644px 0px;}
.country-select-flag.ne {height:15px;background-position:-3666px 0px; width:20px;}
.country-select-flag.nf {height:10px;background-position:-3686px 0px;}
.country-select-flag.ng {height:10px;background-position:-3708px 0px;}
.country-select-flag.ni {height:12px;background-position:-3730px 0px;}
.country-select-flag.nl {height:14px;background-position:-3752px 0px;}
.country-select-flag.no {height:15px;background-position:-3774px 0px;}
.country-select-flag.np {height:15px;background-position:-3796px 0px; width:15px;}
.country-select-flag.nr {height:10px;background-position:-3811px 0px;}
.country-select-flag.nu {height:10px;background-position:-3833px 0px;}
.country-select-flag.nz {height:10px;background-position:-3855px 0px;}
.country-select-flag.om {height:10px;background-position:-3877px 0px;}
.country-select-flag.pa {height:14px;background-position:-3899px 0px;}
.country-select-flag.pe {height:14px;background-position:-3921px 0px;}
.country-select-flag.pf {height:14px;background-position:-3943px 0px;}
.country-select-flag.pg {height:15px;background-position:-3965px 0px;}
.country-select-flag.ph {height:10px;background-position:-3987px 0px;}
.country-select-flag.pk {height:14px;background-position:-4009px 0px;}
.country-select-flag.pl {height:13px;background-position:-4031px 0px;}
.country-select-flag.pm {height:14px;background-position:-4053px 0px;}
.country-select-flag.pn {height:10px;background-position:-4075px 0px;}
.country-select-flag.pr {height:14px;background-position:-4097px 0px;}
.country-select-flag.ps {height:10px;background-position:-4119px 0px;}
.country-select-flag.pt {height:14px;background-position:-4141px 0px;}
.country-select-flag.pw {height:13px;background-position:-4163px 0px;}
.country-select-flag.py {height:11px;background-position:-4185px 0px;}
.country-select-flag.qa {height: 8px;background-position:-4207px 0px;}
.country-select-flag.re {height:14px;background-position:-4229px 0px;}
.country-select-flag.ro {height:14px;background-position:-4251px 0px;}
.country-select-flag.rs {height:14px;background-position:-4273px 0px;}
.country-select-flag.ru {height:14px;background-position:-4295px 0px;}
.country-select-flag.rw {height:14px;background-position:-4317px 0px;}
.country-select-flag.sa {height:14px;background-position:-4339px 0px;}
.country-select-flag.sb {height:10px;background-position:-4361px 0px;}
.country-select-flag.sc {height:10px;background-position:-4383px 0px;}
.country-select-flag.sd {height:10px;background-position:-4405px 0px;}
.country-select-flag.se {height:13px;background-position:-4427px 0px;}
.country-select-flag.sg {height:14px;background-position:-4449px 0px;}
.country-select-flag.sh {height:10px;background-position:-4471px 0px;}
.country-select-flag.si {height:10px;background-position:-4493px 0px;}
.country-select-flag.sj {height:15px;background-position:-4515px 0px;}
.country-select-flag.sk {height:14px;background-position:-4537px 0px;}
.country-select-flag.sl {height:14px;background-position:-4559px 0px;}
.country-select-flag.sm {height:15px;background-position:-4581px 0px;}
.country-select-flag.sn {height:14px;background-position:-4603px 0px;}
.country-select-flag.so {height:14px;background-position:-4625px 0px;}
.country-select-flag.sr {height:14px;background-position:-4647px 0px;}
.country-select-flag.ss {height:10px;background-position:-4669px 0px;}
.country-select-flag.st {height:10px;background-position:-4691px 0px;}
.country-select-flag.sv {height:12px;background-position:-4713px 0px;}
.country-select-flag.sx {height:14px;background-position:-4735px 0px;}
.country-select-flag.sy {height:14px;background-position:-4757px 0px;}
.country-select-flag.sz {height:14px;background-position:-4779px 0px;}
.country-select-flag.ta {height:10px;background-position:-4801px 0px;}
.country-select-flag.tc {height:10px;background-position:-4823px 0px;}
.country-select-flag.td {height:14px;background-position:-4845px 0px;}
.country-select-flag.tf {height:14px;background-position:-4867px 0px;}
.country-select-flag.tg {height:13px;background-position:-4889px 0px;}
.country-select-flag.th {height:14px;background-position:-4911px 0px;}
.country-select-flag.tj {height:10px;background-position:-4933px 0px;}
.country-select-flag.tk {height:10px;background-position:-4955px 0px;}
.country-select-flag.tl {height:10px;background-position:-4977px 0px;}
.country-select-flag.tm {height:14px;background-position:-4999px 0px;}
.country-select-flag.tn {height:14px;background-position:-5021px 0px;}
.country-select-flag.to {height:10px;background-position:-5043px 0px;}
.country-select-flag.tr {height:14px;background-position:-5065px 0px;}
.country-select-flag.tt {height:12px;background-position:-5087px 0px;}
.country-select-flag.tv {height:10px;background-position:-5109px 0px;}
.country-select-flag.tw {height:14px;background-position:-5131px 0px;}
.country-select-flag.tz {height:14px;background-position:-5153px 0px;}
.country-select-flag.ua {height:14px;background-position:-5175px 0px;}
.country-select-flag.ug {height:14px;background-position:-5197px 0px;}
.country-select-flag.um {height:11px;background-position:-5219px 0px;}
.country-select-flag.un {height:14px;background-position:-5241px 0px;}
.country-select-flag.us {height:11px;background-position:-5263px 0px;}
.country-select-flag.uy {height:14px;background-position:-5285px 0px;}
.country-select-flag.uz {height:10px;background-position:-5307px 0px;}
.country-select-flag.va {height:15px;background-position:-5329px 0px; width:17px;}
.country-select-flag.vc {height:14px;background-position:-5346px 0px;}
.country-select-flag.ve {height:14px;background-position:-5368px 0px;}
.country-select-flag.vg {height:10px;background-position:-5390px 0px;}
.country-select-flag.vi {height:14px;background-position:-5412px 0px;}
.country-select-flag.vn {height:14px;background-position:-5434px 0px;}
.country-select-flag.vu {height:12px;background-position:-5456px 0px;}
.country-select-flag.wf {height:14px;background-position:-5478px 0px;}
.country-select-flag.ws {height:10px;background-position:-5500px 0px;}
.country-select-flag.xk {height:15px;background-position:-5522px 0px;}
.country-select-flag.ye {height:14px;background-position:-5544px 0px;}
.country-select-flag.yt {height:14px;background-position:-5566px 0px;}
.country-select-flag.za {height:14px;background-position:-5588px 0px;}
.country-select-flag.zm {height:14px;background-position:-5610px 0px;}
.country-select-flag.zw {height:10px;background-position:-5632px 0px;}
